html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'BuenosAires', sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #777777;
  background: #f9f9f9;
  overflow-x: hidden;
}

@media (min-width: 769px) {
  html,
  body {
    height: 100%;
  }
}

#root {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: 'BuenosAires';
  src: url('assets/fonts/BuenosAiresWeb-Regular.eot'); /* IE9 Compat Modes */
  src: url('assets/fonts/BuenosAiresWeb-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/BuenosAiresWeb-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('assets/fonts/BuenosAiresWeb-Regular.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+000D-FB04;
}

@font-face {
  font-family: 'BuenosAires';
  src: url('assets/fonts/BuenosAiresWeb-Bold.eot'); /* IE9 Compat Modes */
  src: url('assets/fonts/BuenosAiresWeb-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/BuenosAiresWeb-Bold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('assets/fonts/BuenosAiresWeb-Bold.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+000D-FB04;
}

@font-face {
  font-family: 'BuenosAires';
  src: url('assets/fonts/BuenosAiresWeb-BoldItalic.eot'); /* IE9 Compat Modes */
  src: url('assets/fonts/BuenosAiresWeb-BoldItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/BuenosAiresWeb-BoldItalic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('assets/fonts/BuenosAiresWeb-BoldItalic.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+000D-FB04;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('https://fonts.gstatic.com/s/materialicons/v38/flUhRq6tzZclQEJ-Vdg-IuiaDsNa.woff')
    format('woff');
}
